
import "../scss/app.scss";
import.meta.glob(["../fonts/**", "../images/**"]);

import "./bootstrap";
import "intl-tel-input/build/js/utils";

import intl from "./intl";
import InputMask from "./mask_input";
import Splide from "@splidejs/splide";

import Tab from "./tabs";
import Chart from "chart.js/auto";

import Validator from "./validation";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import multiMonthPlugin from "@fullcalendar/multimonth";

import { Calendar } from "@fullcalendar/core";
import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";

const csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

const navigation = document.querySelector(".header-lower");
if (navigation) {
    const navigationHeight = navigation.offsetHeight;
    document.documentElement.style.setProperty(
        "--scrool-padding",
        navigationHeight + "px"
    );
}

const menuToggle = document.querySelector(".menu-toggle");
if (menuToggle) {
    const asideMenu = document.querySelector(".management-menu");
    menuToggle.addEventListener("click", (e) => {
        e.preventDefault();

        if (asideMenu.classList.contains("toggle-menu")) {
            asideMenu.classList.remove("toggle-menu");
        } else {
            asideMenu.classList.add("toggle-menu");
        }
    });
}

const listItems = document.querySelectorAll(".list-item");
let activeItem = null;
listItems.forEach((element) => {
    element.addEventListener("click", (e) => {
        // e.preventDefault();
        const item = e.currentTarget;

        const menuLink = item.querySelector("a");
        if (menuLink.classList.contains("active")) {
            return;
        } else {
            if (activeItem) {
                activeItem.classList.remove("active");
            }

            activeItem = menuLink;
            activeItem.classList.add("active");
        }
    });
});

const collapseMenus = document.querySelectorAll(".collapsible-item");
let collapsedMenu = null;
collapseMenus.forEach((element) => {
    element.addEventListener("click", (e) => {
        // e.preventDefault();
        const targetItem = e.currentTarget;
        const target = e.target;

        if (targetItem.classList.contains("collapsed")) {
            if (!target.classList.contains("item")) {
                collapsedMenu = null;
                targetItem.classList.remove("collapsed");
            }
            return;
        } else {
            if (collapsedMenu) {
                collapsedMenu.classList.remove("collapsed");
            }

            collapsedMenu = targetItem;
            collapsedMenu.classList.add("collapsed");
        }
    });
});

const btn = document.querySelector("#backTop");
if (btn) {
    window.addEventListener("scroll", function () {
        if (window.pageYOffset > 300) {
            btn.classList.add("show");
        } else {
            btn.classList.remove("show");
        }
    });

    btn.addEventListener("click", function (e) {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: "smooth" });
    });
}

// phonenumber

new intl({
    phone_input: document.querySelector("input[name='phone']"),
    hiddent_input: document.querySelector("input[name='phone_number']"),
});

new intl({
    phone_input: document.querySelector("input[name='employee_phonenumber']"),
    hiddent_input: document.querySelector("input[name='phone']"),
});

// input mask
var mask_input = document.querySelector("input[name='postal_code']");
var address_input = document.querySelector("input[name='address']");
if (mask_input && address_input) {
    new InputMask(
        mask_input,
        {
            mask: "###-####",
            autoPlaceholder: "aggressive",
            placeholder: "eg: 211-2331",
        },
        (value) => {
            fetch(
                `https://app.zipcodebase.com/api/v1/search?apikey=24a02b60-c7a9-11ed-aaf0-15b28e4100e0&codes=${value}`
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.results) {
                        var result = data.results[value][0];
                        address_input.value = `${result.country_code}, ${result.state_en}, ${result.province}, ${result.city_en}`;
                    }
                })
                .catch();
        }
    );
}

// dismiss alert
var alertCloseButton = document.querySelector(".alert-dismiss");

if (alertCloseButton) {
    alertCloseButton.addEventListener("click", (event) => {
        const alert = alertCloseButton.parentElement;
        alert.remove();
        event.preventDefault();
    });
}

if (document.getElementById("student-appointment")) {
    const container = document.getElementById("student-appointment");
    const id = container.getAttribute("data-id");
    const calendar = new Calendar(container, {
        dayMaxEventRows: true,
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
        initialView: "timeGridDay",
        views: {
            timeGrid: {
                dayMaxEventRows: 6,
            },
        },
        headerToolbar: {
            left: "prev,next today",
            center: "title",
            right: "timeGridDay,timeGridWeek,dayGridMonth,listWeek",
        },
        events: {
            url: `/appointments/student/${id}`,
            method: "GET",
            success: (data) => {
                const events = [];
                data.forEach(function (appointment) {
                    events.push(appointment);
                });
                return events;
            },
        },
    });

    calendar.render();
}

// calendar

if (document.getElementById("driving-spot-calendard")) {
    const controls = document.querySelector(".controls");
    const cancel = controls.querySelector(".btn-danger");
    const spotId = controls.querySelector('input[name="spot_id"]');

    var selectedEvent = null;

    cancel.addEventListener("click", function (e) {
        e.preventDefault();
        clearSelectedEvent();
        spotId.value = "";
    });

    const handleSelectEvent = (info) => {
        clearSelectedEvent();

        if (!controls.classList.contains("activated")) {
            controls.classList.add("activated");
        }

        selectedEvent = info.event;
        const classNames = selectedEvent.extendedProps.classNames || [];
        if (classNames.includes("selected")) {
            selectedEvent.setProp(
                "classNames",
                classNames.filter(function (className) {
                    return className !== "selected";
                })
            );
        } else {
            selectedEvent.setProp("classNames", classNames.concat("selected"));
        }

        spotId.value = selectedEvent.id;
    };

    const clearSelectedEvent = () => {
        calendar.getEvents().forEach(function (event) {
            event.setProp(
                "classNames",
                (event.extendedProps.classNames || []).filter(function (
                    className
                ) {
                    return className !== "selected";
                })
            );
        });

        controls.classList.remove("activated");
        selectedEvent = null;
    };

    const calendar = new Calendar(
        document.getElementById("driving-spot-calendard"),
        {
            dayMaxEventRows: true,
            plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
            initialView: "listWeek",
            timeZone: "UTC",
            views: {
                timeGrid: {
                    dayMaxEventRows: 6,
                },
            },
            headerToolbar: {
                left: "prev,next today",
                center: "title",
                right: "listWeek,timeGridDay,timeGridWeek,dayGridMonth",
            },
            events: {
                url: "/driving/appointment/spots",
                method: "GET",
                success: (data) => {
                    const events = [];
                    data.forEach(function (appointment) {
                        events.push(appointment);
                    });

                    events.sort((a, b) => {
                        return new Date(a.start) - new Date(b.start);
                    });

                    return events;
                },
            },

            eventClick: handleSelectEvent,
        }
    );

    calendar.render();
}

const avatars = document.querySelectorAll(".avatar");

avatars.forEach((avatar) => {
    const name = avatar.getAttribute("data-name");
    const svg = createAvatar(initials, {
        seed: name,
    });

    avatar.innerHTML = svg;
});

// Tab
new Tab(".tab");

//
const edit = document.querySelector(".control-edit");
const cancel = document.querySelector(".control-cancel");
const save = document.querySelector(".control-save");

if (edit && cancel && save) {
    edit.addEventListener("click", () => {
        var cells = document.querySelectorAll("td.editable");
        for (var j = 0; j < cells.length; j++) {
            var value = cells[j].textContent;
            var field = cells[j].getAttribute("data-field");
            var type = cells[j].getAttribute("data-type");

            if (type == "dropdown") {
                const data = JSON.parse(cells[j].getAttribute("data-list"));
                const item_key = cells[j].getAttribute("data-item-key");
                const item_value = cells[j].getAttribute("data-item-value");

                if (value) {
                    if (item_key) {
                        const data_value = data.find(
                            (item) => item[item_key.trim()] === value.trim()
                        );

                        if (data_value) {
                            var selectHtml =
                                '<select name="' +
                                field +
                                '" class="editable-input"><option value="' +
                                data_value[item_value.trim()] +
                                '">' +
                                value +
                                "</option>";
                        } else {
                            var selectHtml =
                                '<select name="' +
                                field +
                                '" class="editable-input"><option value="' +
                                value +
                                '">' +
                                value +
                                "</option>";
                        }
                    } else {
                        var selectHtml =
                            '<select name="' +
                            field +
                            '" class="editable-input"><option value="' +
                            value +
                            '">' +
                            value +
                            "</option>";
                    }
                } else {
                    var selectHtml =
                        '<select name="' +
                        field +
                        '" class="editable-input"><option></option>';
                }

                for (var k = 0; k < data.length; k++) {
                    if (item_key && item_value) {
                        // selectHtml += '<option value="' + data[k][value] + '">' + data[k][key] + '</option>';
                        selectHtml +=
                            '<option value="' +
                            data[k][item_value] +
                            '">' +
                            data[k][item_key] +
                            "</option>";
                    } else {
                        selectHtml +=
                            '<option value="' +
                            data[k] +
                            '">' +
                            data[k] +
                            "</option>";
                    }
                }

                selectHtml += "</select>";

                cells[j].innerHTML = selectHtml;
            } else {
                if (type) {
                    cells[j].innerHTML =
                        '<input type="' +
                        type +
                        '" name="' +
                        field +
                        '" value="' +
                        value +
                        '" class="editable-input">';
                } else {
                    cells[j].innerHTML =
                        '<input type="text" name="' +
                        field +
                        '" value="' +
                        value +
                        '" class="editable-input">';
                }
            }
        }
        edit.style.display = "none";
        cancel.style.display = "flex";
        save.style.display = "flex";

        cancel.addEventListener("click", () => {
            var cells = document.querySelectorAll("td.editable");
            for (var j = 0; j < cells.length; j++) {
                cells[j].innerHTML = cells[j].getAttribute("data-value");
            }

            edit.style.display = "flex";
            cancel.style.display = "none";
            save.style.display = "none";
        });
    });
}

/// images
const inputFile = document.querySelector('input[name="picture"]');
const previewImage = document.querySelector(".preview");
const cancelButton = document.querySelector(".btn-cancel");

if (inputFile && previewImage && cancelButton) {
    inputFile.addEventListener("change", () => {
        const file = inputFile.files[0];
        const defaultImage = previewImage.getAttribute("data-default");

        if (
            file &&
            file.type.includes("image/") &&
            (file.type.includes("jpeg") || file.type.includes("png"))
        ) {
            const url = URL.createObjectURL(file);
            previewImage.setAttribute("src", url);
        } else {
            previewImage.setAttribute("src", defaultImage);
            inputFile.value = "";
        }

        cancelButton.addEventListener("click", (e) => {
            e.preventDefault();
            previewImage.setAttribute("src", defaultImage);
            inputFile.value = "";
        });
    });
}

// get PIN
var getPin = document.querySelector("a.pin-btn");
if (getPin) {
    var roll_number = document.querySelector('input[name="roll_number"]');
    new InputMask(roll_number, {
        mask: "####-#####",
        autoPlaceholder: "aggressive",
        placeholder: "####-#####",
    });

    getPin.addEventListener("click", (event) => {
        event.preventDefault();

        getPin.disabled = true;
        getPin.classList.add('disabled');

        if (roll_number && roll_number.value) {
            var data = {
                roll_number: roll_number.value,
            };

            fetch("/student/request/access_code", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-TOKEN": csrfToken,
                },
                body: JSON.stringify(data),
            })
            .finally(() => {
                setTimeout(function() {
                    getPin.classList.remove('disabled');
                    getPin.disabled = false;
                }, 50000);
            });
        }
    });
}

// chart
function getMonthName(month) {
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    return months[month - 1];
}

fetch("/management/student/join/report", {
    method: "GET",
    headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": csrfToken,
    },
})
    .then((response) => response.json())
    .then((data) => {
        const processedData = {};

        data.forEach((item) => {
            const { status, year, month, count } = item;
            const label = `${getMonthName(month)} ${year}`;

            if (!processedData[label]) {
                processedData[label] = {};
            }
            processedData[label][status] = count;
        });

        const labels = Object.keys(processedData);

        const statusSet = new Set();
        labels.forEach((label) => {
            Object.keys(processedData[label]).forEach((status) =>
                statusSet.add(status)
            );
        });
        const statusArray = Array.from(statusSet);

        // Initialize an object to hold the counts of each status for each month
        const statusCounts = {};
        statusArray.forEach((status) => (statusCounts[status] = []));

        // Iterate through each month and fill in the status counts
        labels.forEach((label) => {
            const statusData = processedData[label];
            statusArray.forEach((status) => {
                statusCounts[status].push(statusData[status] || 0);
            });
        });

        const datasets = statusArray.map((status) => ({
            label: status,
            data: statusCounts[status],
            // borderColor: getRandomColor(),
            fill: false,
        }));

        const ctx = document.getElementById("chart").getContext("2d");
        new Chart(ctx, {
            type: "line",
            data: {
                labels: labels,
                datasets: datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: "top",
                    },
                    title: {
                        display: true,
                        text: "Status of Students by Month",
                    },
                },
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });
    })
    .catch((error) => {});

if (document.querySelector(".instructor-calendar")) {
    const calendar = new Calendar(
        document.querySelector(".instructor-calendar"),
        {
            plugins: [
                multiMonthPlugin,
                dayGridPlugin,
                timeGridPlugin,
                listPlugin,
            ],
            initialView: "timeGridDay",
            events: "/appointments/driving",
            headerToolbar: {
                left: "prev,next",
                center: "title",
                right: "multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay,listWeek",
            },
        }
    );

    calendar.render();
}

document.addEventListener("DOMContentLoaded", function () {
    if (
        document.querySelector("#main-slider") &&
        document.querySelector("#thumbnail-slider")
    ) {
        var splide = new Splide("#main-slider", {
            rewind: true,
            pagination: false,
            arrows: true,
            // isNavigation: true,
            autoplay: true,
            interval: 4000,
        });
        var thumbnail = new Splide("#thumbnail-slider", {
            fixedWidth: 100,
            fixedHeight: 60,
            gap: 10,
            rewind: true,
            pagination: false,
            cover: true,
            isNavigation: true,
            // focus: 'center',
            breakpoints: {
                600: {
                    fixedWidth: 60,
                    fixedHeight: 44,
                },
            },
        });

        splide.sync(thumbnail);

        splide.mount();
        thumbnail.mount();
    }
});

const openHamburgerMenu = document.querySelector(".hamburget-menu");
const closeHamburgerMenu = document.querySelector('.close')

if(openHamburgerMenu && closeHamburgerMenu){
    const mobileMenu = document.querySelector('.mobile-menu');
    if(mobileMenu){
        openHamburgerMenu.addEventListener('click', () => {
            mobileMenu.classList.add('open');
        });

        closeHamburgerMenu.addEventListener('click', () => {
            mobileMenu.classList.remove('open');
        })
    }
}


if (document.getElementById("driving-spot")) {

    const calendar = new Calendar(
        document.getElementById("driving-spot"),
        {
            dayMaxEventRows: true,
            plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
            initialView: "listWeek",
            timeZone: "UTC",
            views: {
                timeGrid: {
                    dayMaxEventRows: 6,
                },
            },
            headerToolbar: {
                left: "prev,next today",
                center: "title",
                right: "listWeek,timeGridDay,timeGridWeek,dayGridMonth",
            },
            events: {
                url: "/driving/appointment/spots",
                method: "GET",
                success: (data) => {
                    
                    const events = [];
                    data.forEach(function (appointment) {
                        events.push(appointment);
                    });

                    events.sort((a, b) => {
                        return new Date(a.start) - new Date(b.start);
                    });

                    return events;
                },
                failure: (error) => {
                    console.log(error)
                },
            },
        }
    );

    calendar.render();
}

const form = document.querySelector('form.contact-form');

if(form) {
    form.addEventListener('submit', (e) => {
        e.preventDefault();
    
        var data = new FormData(form);

        const validation = {
            full_name: 'required',
            email: 'required',
            subject: 'required',
            phone_number: 'required',
            message: 'required|max:500'
        }

        const item = Object.fromEntries(
            Object.keys(validation).map(key => [key, data.get(key)])
        );

        const validator = new Validator(item, validation);

        if(validator.validate()) {
            fetch("/contact", {
                method: "POST",
                headers: {
                    // "Content-Type": "application/json",
                    "X-CSRF-TOKEN": csrfToken,
                },
                body: data,
            })
            .then(response => response.json())
            .then((data) => 
            {
                if (data.errors) {
                    setErrorMessge(data.errors);
                } else {
                    form.reset();
                    var alert = document.createElement('div');
                    alert.className = 'alert alert-success'; // You can customize the class for styling
                    alert.textContent = "Thank you! Your form has been submitted successfully.";

                    form.insertBefore(alert, form.firstChild);
                }
            })
            // .finally(() => {
            //     form.reset();
            // });
        } else  {
            setErrorMessge(validator.errors)
        }
    });
}

function setErrorMessge(errors)
{
    const inputs = form.querySelectorAll('.form-group.has-error');
    inputs.forEach(formGroup => {
        formGroup.classList.remove('has-error');
    });

    Object.keys(errors).forEach(field => {
        const messages = errors[field];
        
        const inputElement = form.querySelector(`[name="${field}"]`);

        if(inputElement) {
            const invalidFeedback = inputElement.parentElement.querySelector('.invalid-feedback');

            if (invalidFeedback) {
                invalidFeedback.querySelector('span').textContent = messages[0];

                const formGroup = inputElement.closest('.form-group');

                if (formGroup) {
                    formGroup.classList.add('has-error');
                }
            }
        }
    });
}

const uploaders = document.querySelectorAll('.uploader');
const input = document.querySelector('input[name="pictures[]"]');
uploaders.forEach(upload => {
    upload.addEventListener('click', () => {
        input.click();
    });
});

if(input) {
    input.addEventListener('change', (event) => {
        const form = event.target.closest('form');
        if(form) {
            form.submit();
        }
    });
}